import React from "react";
import styled from "styled-components";
import LoadingSmall from "../loadingSmall";

export const StyledInput = styled.input`
  padding: 0 10px;
  width: calc(100% - 20px);
  height: 35px;
  cursor: text;
  text-align: left;
  font-size: 13px;
  line-height: 1.5;
  color: #595959;
  background-color: #fff;
  background-image: none;
  border: 1px solid ${({ error }) => (error ? "#721c24" : "#e9e9e9 ")};
  border-radius: 4px;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-bottom: ${({ bottom }) => bottom};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const Input = ({
  name,
  value,
  onChange,
  placeholder,
  type,
  error,
  bottom,
  onBlur,
  loading,
}) => {
  return loading ? (
    <LoadingSmall />
  ) : (
    <StyledInput
      bottom={bottom === null ? 0 : bottom}
      error={error}
      type={type}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      onBlur={onBlur}
    />
  );
};

export default Input;

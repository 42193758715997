import React, {useState} from "react";
import classNames from "classnames";
import {UseUser} from "../../hooks/context/user";
import {dispatcher} from "./menu";
import {
  StyledContainer,
  StyledUl,
  IconDispatcher,
  StyledLinkLogo,
  IconRatings,
  IconCandict,
  IconGame,
  IconOpen,
  StyledLink,
  StyledLinkCustom,
  StyledBotton, IconGroagenda,
} from "./styled";
import {withRouter} from "react-router-dom";
import {apiURL} from "../../libs/helpers/methods";

function Sidebar({match, history, menu}) {
  const {user} = UseUser();
  const [open, setOpen] = useState({
    dispatcher: match.path === "/dispatcher/:type" ? true : false,
    grogames: match.path === "/grogames" ? true : false,
  });
  const [openSub, setOpenSub] = useState({grogamesseries: false});

  const openSubMenu = (value) => {
    const result = {
      dispatcher: value === "dispatcher" ? !open.dispatcher : false,
      grogames: value === "grogames" ? !open.grogames : false,
    };
    setOpen(result);
  };
  const openSubNivel2Menu = (value) => {
    const result = {
      grogamesseries:
        value === "grogamesseries" ? !openSub.grogamesseries : false,
    };
    setOpenSub(result);
  };

  const goTo = (route) => {
    history.push(route);
  };

  return (
    <StyledContainer id="sidebar" className={menu}>
      <StyledLinkLogo to="/">
        {menu === "desktop-menu-close" && (
          <img src="/static/logo_50.png" alt="H2B Hipotecas"/>
        )}
        {(menu === "desktop-menu-open" || menu === "phone-menu-open") && (
          <img src="/static/logo_50.png" alt="H2B Hipotecas"/>
        )}
      </StyledLinkLogo>
      <StyledUl>
        {user.menu["dispatcher"] !== undefined && (
          <li
            className={classNames({
              minimize: menu === "desktop-menu-close",
            })}
          >
            <StyledBotton onClick={() => openSubMenu("dispatcher")}>
              <IconDispatcher/>
              <span>Dispatcher</span>
              {menu === "desktop-menu-open" && <IconOpen/>}
            </StyledBotton>
            {user.menu["dispatcher"].length > 0 && (
              <ul className={classNames("submenu", {open: open.dispatcher})}>
                {dispatcher.map((item) => {
                  return user.menu["dispatcher"].map((res, index) => {
                    return (
                      res === item.key && (
                        <li key={index}>
                          <StyledLink to={item.route}>
                            <span>
                              {menu === "desktop-menu-open"
                                ? item.name
                                : item.domine}
                            </span>
                          </StyledLink>
                        </li>
                      )
                    );
                  });
                })}
              </ul>
            )}
          </li>
        )}
        {user.menu["users"] !== undefined && (
          <li
            className={classNames({
              minimize: menu === "desktop-menu-close",
            })}
          >
            <StyledBotton onClick={() => goTo("/leads")}>
              <IconCandict/>
              <span>Candidatos</span>
            </StyledBotton>
          </li>
        )}
      </StyledUl>
    </StyledContainer>
  );
}

export default withRouter(Sidebar);

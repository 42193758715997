import React from 'react'
import Select from 'react-select';

const ProvinceItem = ({ province, loadTeamsProvinces, handleBlur, handleChange, convertDataTeams }) => {
    return (
        <div>
            <div>
                <p>
                    {province.name}
                </p>
            </div>
            <div>
                <Select
                    value={province.teams.length ? loadTeamsProvinces(province.teams) : []}
                    onBlur={() => handleBlur(province)}
                    onChange={(selectedOption) => handleChange(selectedOption, province.id)}
                    options={convertDataTeams()}
                    isMulti                    
                />
            </div>
        </div>
    )
}

export default ProvinceItem
import React from 'react';
import styled from 'styled-components';

const StyledText = styled.h3`
    font-size: 19px;
    font-weight: 500;
    color: #788195;
    margin-right: 17px;
    margin-bottom: 30px;
    white-space: nowrap;
    text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
    text-align: center;
    margin: 0;
`
export default function NotData () { return <StyledText>🧐 No hay datos.</StyledText> }


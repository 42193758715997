import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import Card from '../../components/card';
import UserItem from './UserItem';
import { useAlert } from '../../hooks/context/alert';
import { getDispatcherUsersAction, updateCoefficientUserAction } from '../../actions';
import { messages } from '../../libs/helpers/message';

const StyledContainer = styled.div`   
    display: flex;
    flex-direction: column;    

    > div {
        > div {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }        
`

const Users = ({ setLoading }) => {
    const { openAlert } = useAlert();
    const [users, setUsers] = useState(null);

    useEffect(() => {
        getDispatcherUsersAction()
            .then((res) => {
                if (res?.teams?.length) {
                    setUsers(res.teams)
                } else {
                    setUsers([])
                }
            })
            .catch(() => {
                openAlert("error", "Usuarios", messages.DISPATCHER_ERROR_LOAD_USERS_MORTGAGE);
            })
    }, []);

    const handleChange = (selectedOption, teamName, userId) => {
        let updateCoefficient = users.map((team) => {
            if (team.name === teamName) {
                return {
                    ...team,
                    users: team.users.map((user) => {
                        if (user._id === userId) {
                            return {
                                ...user,
                                dispatcher_coefficient: selectedOption
                            }
                        }
                        return user
                    })

                }
            }
            return team
        })
        setUsers(updateCoefficient)
    }

    const loadCoefficientUser = (data) => {
        if (!data.value) {
            return {
                value: data,
                label: data
            }
        }
        return data
    }

    const handleBlur = (data) => {
        if (data.dispatcher_coefficient) {
            setLoading(true)
            updateCoefficientUserAction({ id: data._id, dispatcher_coefficient: parseInt(data.dispatcher_coefficient.value) })
                .then(res => {
                    if (!res.error) {
                        openAlert(
                            "success",
                            `Usuario`,
                            messages.DISPATCHER_UPDATE_SUCCESS_USERS_MORTGAGE
                        );
                    } else {
                        openAlert(
                            "error",
                            `Usuario`,
                            messages.DISPATCHER_UPDATE_ERROR_USERS_MORTGAGE
                        );
                    }
                })
                .catch(() => {
                    openAlert(
                        "error",
                        `Usuario`,
                        messages.DISPATCHER_UPDATE_ERROR_USERS_MORTGAGE
                    );
                })
                .finally(() => {
                    setLoading(false);
                })
        }
    };

    return (
        <Card title="Coeficiente usuarios" fullWeight>
            {
                users ?
                    <StyledContainer>
                        {
                            users.length ?
                                users.map((team) => {
                                    return (
                                        <Card title={team.name} fullWeight key={team.name}>
                                            {
                                                team.users.map((user) => {
                                                    return (
                                                        <UserItem
                                                            key={user._id}
                                                            user={user}
                                                            team={team}
                                                            loadCoefficientUser={loadCoefficientUser}
                                                            handleBlur={handleBlur}
                                                            handleChange={handleChange}
                                                        />
                                                    )
                                                })
                                            }
                                        </Card>
                                    )
                                })
                                :
                                <div>
                                    <p>{messages.DISPATCHER_NO_RECORDS}</p>
                                </div>
                        }
                    </StyledContainer>
                    :
                    null
            }
        </Card>
    )
}

export default Users
export const dispatcher = [
    {
        key: 'dispatcher.mortgages.teams',
        name: 'Equipo Hipotecas',
        domine: 'E.H',
        route: '/dispatcher/equipos-hipotecas'
    },
    {
        key: 'dispatcher.mortgages.users',
        name: 'Assesores Hipotecas',
        domine: 'A.H',
        route: '/dispatcher/assesores-hipotecas'
    }
]

export const users = [
    {
        key: 'users.me',
        name: 'Mi perfil',
        route: '/dispatcher/equipos-inmuebles'
    }
]
import axios from "axios";
import { getToken } from "../libs/cookies";
import { messages } from "../libs/helpers/message";
import { apiURL } from "../libs/helpers/methods";

export const tokenExpired = (res) => {
  if (
    res.response &&
    res.response.status &&
    parseInt(res.response.status) === 401
  ) {
    window.location.href = "/login";
  } else if (
    res.response &&
    res.response.status &&
    parseInt(res.response.status) === 500
  ) {
    window.location.href = "/500";
  }
};

// Lead request
export const getApiLeads = ({
  limit,
  offset,
  // type,
  team,
  user,
  // opportunity_type,
  provenance,
}) => {
  return axios
    .get(
      `${apiURL()}/api/leads/list?limit=${limit}&offset=${offset}&team=${team}&user=${user}&provenance=${provenance}`,
      { headers: { Authorization: `Bearer ${getToken()}` } }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const getProvences = (data) => {
  return axios
    .get(
      `${apiURL()}/api/provenances/leads?type=${data.type}&opportunity_type=${data.opportunity_type}`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    )
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const getApiFilters = ({ team, user }) => {
  return axios
    .get(
      `${apiURL()}/api/leads/filters?team=${team}&user=${user}`,
      { headers: { Authorization: `Bearer ${getToken()}` } }
    )
    .then((res) => {
      if (res.data.meta.success) {
        return res.data.data;
      }
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

// Dispatcher request

export const getApiDispatcher = ({ type, option }) => {
  return axios
    .get(`${apiURL()}/api/dispatcher/${type}/${option}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      if (res.data.meta.success) {
        if (option === "users") {
          return res.data.data.teams;
        } else {
          return res.data.data;
        }
      }
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const updateApiDispatcherAdviser = ({ id, active, type }, openAlert) => {
  return axios
    .put(
      `${apiURL()}/api/dispatcher/${type}/user/${id}`,
      { active },
      { headers: { Authorization: `Bearer ${getToken()}` } }
    )
    .then((res) => {
      if (res.data.meta.success) {
        openAlert(
          "success",
          `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
          messages.DISPATCHER_ASSES_UPDATE_SUCCESS
        );
      } else {
        openAlert(
          "error",
          `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
          messages.DISPATCHER_ASSES_UPDATE_ERROR
        );
      }
    })
    .catch((err) => {
      tokenExpired(err);
      openAlert(
        "error",
        `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
        messages.DISPATCHER_ASSES_UPDATE_ERROR
      );
    });
};

export const updateApiDispatcherTeam = (
  { id, work_pressure, type },
  openAlert
) => {
  return axios
    .post(
      `${apiURL()}/api/dispatcher/${type}/team/${id}`,
      { work_pressure },
      { headers: { Authorization: `Bearer ${getToken()}` } }
    )
    .then((res) => {
      if (res.data.meta.success) {
        openAlert(
          "success",
          `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
          messages.DISPATCHER_TEAM_UPDATE_CHARGE_PRESSURE_SUCCESS
        );
      } else {
        openAlert(
          "error",
          `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
          messages.DISPATCHER_TEAM_UPDATE_ERROR
        );
      }
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const updateApiAlgorithms = ({ active, type, id }, openAlert) => {
  axios
    .post(
      `${apiURL()}/api/dispatcher/${type}/algorithm/${id}`,
      { active },
      { headers: { Authorization: `Bearer ${getToken()}` } }
    )
    .then((res) => {
      if (res.data.meta.success) {
        openAlert(
          "success",
          `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
          messages.DISPATCHER_ALGORITMO_UPDATE_SUCCESS
        );
      } else {
        openAlert(
          "error",
          `${type === "mortgages" ? "Hipotecas" : "Inmuebles"}`,
          messages.DISPATCHER_ALGORITMO_UPDATE_ERROR
        );
      }
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

//Onseginal
export const sendOnsignal = ({ user_id, country, language_code, device }) => {
  axios.post(
    `${apiURL()}/api/notifications-push/notification-push`,
    {
      user_id,
      country,
      language_code,
      device,
    },
    { headers: { Authorization: `Bearer ${getToken()}` } }
  );
};

//Rerefencia
export const validareRefencie = (property_reference) => {
  return axios
    .get(
      `${apiURL()}/api/properties/property/validate?property_reference=${property_reference}`,
      {
        headers: { Authorization: `Bearer ${getToken()}` },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

//Rating
export const ratingTeam = (year) => {
  return axios
    .get(`${apiURL()}/api/rating/properties/offline/teams/${year}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};
export const ratingUser = (year) => {
  return axios
    .get(`${apiURL()}/api/rating/properties/offline/users/${year}`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getProvinces = () => {
  return axios
    .get(`${apiURL()}/api/dispatcher/mortgages/provinces`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const getTeams = () => {
  return axios
    .get(`${apiURL()}/api/dispatcher/mortgages/teams`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const updateProvincesAction = (data) => {  
  return new Promise((response, error) => {
    axios
      .put(
        `${apiURL()}/api/dispatcher/mortgages/province/${data.id}`,
        { teams: data.teams },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then(res => {
        if (res.data.meta.success) {
          response({ error: false, data: res.data.data })
        } else {
          response({ error: true, message: 'Error al enviar los datos' })
        }
      })
      .catch(err => {
        error(tokenExpired(err))
      })
  })
}

export const getDispatcherUsersAction = () => {
  return axios
    .get(`${apiURL()}/api/dispatcher/mortgages/users`, {
      headers: { Authorization: `Bearer ${getToken()}` },
    })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      tokenExpired(err);
    });
};

export const updateCoefficientUserAction = (data) => {
  return new Promise((response, error) => {
    axios
      .put(
        `${apiURL()}/api/dispatcher/mortgages/user/${data.id}`,
        { dispatcher_coefficient: data.dispatcher_coefficient },
        { headers: { Authorization: `Bearer ${getToken()}` } }
      )
      .then(res => {        
        if (res.data.meta.success) {
          response({ error: false, data: res.data.data })
        } else {
          response({ error: true, message: 'Error al enviar los datos' })
        }
      })
      .catch(err => {        
        error(tokenExpired(err))
      })
  })
}

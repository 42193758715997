import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { useAlert } from '../../hooks/context/alert';
import { getProvinces, getTeams, updateProvincesAction } from '../../actions';
import Card from '../../components/card';
import { messages } from '../../libs/helpers/message';
import ProvinceItem from './ProvinceItem';

const StyledContainer = styled.div`   
    display: flex;
    flex-direction: column;
    gap: 24px;

    > div {
        display: grid;
        grid-template-columns: 1fr 3fr;
        align-items: center;
        gap: 10px;
    }

    p {
        margin: 0;
        font-size: 15px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    @media (max-width: 800px) {
        > div {
            grid-template-columns: 1fr;                        
        }
    }
`

const Provinces = ({ setLoading }) => {
    const { openAlert } = useAlert();
    const [provinces, setProvinces] = useState(null);
    const [teams, setTeams] = useState(null);

    useEffect(() => {
        getProvinces()
            .then((res) => {
                if (res?.provinces?.length) {
                    setProvinces(res.provinces)

                    getTeams()
                        .then((res) => {
                            if (res?.teams?.length) {
                                setTeams(res.teams)
                            } else {
                                setTeams([])
                            }
                        })
                        .catch(() => {
                            openAlert("error", "Equipos", messages.DISPATCHER_ERROR_LOAD_TEAMS_MORTGAGE);
                        })
                        .finally(() => {
                            setLoading(false)
                        })

                } else {
                    setProvinces([])
                }
            })
            .catch(() => {
                openAlert("error", "Provincias", messages.DISPATCHER_ERROR_LOAD_PROVINCES);
            })
            .finally(() => {
                setLoading(false)
            })
    }, []);

    const convertDataTeams = () => {
        let newTeams = []
        teams.map((team) => {
            newTeams = [...newTeams, {
                value: team.id,
                label: team.name,
                work_pressure: team.work_pressure
            }]
        })

        return newTeams
    }

    const handleChange = (selectedOption, provinceId) => {
        let updateTeamsProvinces = provinces.map((item) => {
            if (item.id === provinceId) {
                return {
                    ...item,
                    teams: selectedOption
                }
            }
            return item
        })
        setProvinces(updateTeamsProvinces)
    };

    const handleBlur = (data) => {        
        if (data.teams.length) {
            setLoading(true)
            updateProvincesAction({ id: data.id, teams: sendTeams(data.teams) })
                .then(res => {
                    if (!res.error) {
                        openAlert(
                            "success",
                            `Provincia`,
                            messages.DISPATCHER_UPDATE_SUCCESS_PROVINCES
                        );
                    } else {
                        openAlert(
                            "error",
                            `Provincia`,
                            messages.DISPATCHER_UPDATE_ERROR_PROVINCES
                        );
                    }
                })
                .catch(() => {
                    openAlert(
                        "error",
                        `Provincia`,
                        messages.DISPATCHER_UPDATE_ERROR_PROVINCES
                    );
                })
                .finally(() => {
                    setLoading(false);
                })
        } else {
            openAlert(
                "error",
                `Provincia`,
                messages.DISPATCHER_ONE_PROVINCE_AT_LEAST
            );
        }
    };

    const sendTeams = (data) => {
        let arrayTeams = []
        data.map((team) => {
            arrayTeams = [...arrayTeams, team.value || team.id]
        })
        return arrayTeams
    }

    const loadTeamsProvinces = (data) => {
        if (!data[0].value) {
            let newTeams = []
            data.map((item) => {
                newTeams = [...newTeams, {
                    value: item.id,
                    label: item.name
                }]
            })
            return newTeams
        }
        return data.team
    }

    return (
        <Card title="Provincias" fullWeight>
            {
                (provinces && teams) ?
                    <StyledContainer>
                        {
                            (provinces.length && teams.length) ?
                                provinces.map((province) => {
                                    return (
                                        <ProvinceItem
                                            key={province.id}
                                            province={province}
                                            loadTeamsProvinces={loadTeamsProvinces}
                                            handleBlur={handleBlur}
                                            handleChange={handleChange}
                                            convertDataTeams={convertDataTeams}
                                        />
                                    )
                                })
                                :
                                <div>
                                    <p>{messages.DISPATCHER_NO_RECORDS}</p>
                                </div>
                        }
                    </StyledContainer>
                    :
                    null
            }
        </Card>
    )
}

export default Provinces
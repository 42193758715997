import React, { useState } from "react";
//components
import Title from "../../components/ui/title";
import Loading from "../../components/loading";

//styles
import { StyledContainer } from "./styles";
import Provinces from "./Provinces";
import Users from "./Users";

function Teams({ type, typeName }) {
  const [loading, setLoading] = useState(true);

  return (
    <>
      <Title text={`Equipos ${typeName} online`} />
      {loading && <Loading />}

      <StyledContainer>
        <Provinces setLoading={setLoading} />
        <Users setLoading={setLoading} />
      </StyledContainer>
    </>
  );
}

export default Teams;
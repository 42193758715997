import React from 'react'
import Select from 'react-select';
import styled from "styled-components";

const StyledContent = styled.div`
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: center;
    gap: 10px;

    p {
        margin: 0;
        font-size: 15px;
    }

    @media (max-width: 800px) {        
        grid-template-columns: 1fr;                                
    }
`

const UserItem = ({ user, team, loadCoefficientUser, handleBlur, handleChange }) => {

    const options = [
        { value: '100', label: '100' },
        { value: '150', label: '150' },
        { value: '200', label: '200' },
        { value: '250', label: '250' },
        { value: '300', label: '300' },
        { value: '350', label: '350' },
        { value: '400', label: '400' },
    ];

    return (
        <StyledContent>
            <p>
                {user.first_name} {user.last_name} {user.dispatcher_active ? '' : '(inactivo)'}
            </p>
            <div>
                <Select
                    value={user.dispatcher_coefficient ? loadCoefficientUser(user.dispatcher_coefficient) : null}
                    onBlur={() => handleBlur(user)}
                    onChange={(selectedOption) => handleChange(selectedOption, team.name, user._id)}
                    options={options}
                    menuPortalTarget={document.body}
                    menuPosition={'fixed'}
                />
            </div>
        </StyledContent>
    )
}

export default UserItem